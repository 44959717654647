import React from 'react'
import { MainLayout } from '@cmp/site'
import LoadingPage from '../LoadingPage'
import { GlobalDataProvider } from '@src/data/global-data'
import { Seo } from '@kogk/common'
import { seoPropertiesToProps } from '@src/utils'
import { useAuthentication } from '@src/context/Authentication'
import sliceMapping from '@src/data/prismic-slice-mapping/page-slices'
import { makeSlices } from '@kogk/gatsby-theme-prismic'

const Slices = makeSlices(sliceMapping)

export default ({ loginpage, language, seoProperties, altUrls, children }) => {
  const { user, loading } = useAuthentication()

  if (!loginpage || user?.heimsforeldri) {
    return typeof children === 'function' ? children() : children
  }

  if (loading) {
    return <LoadingPage />
  }

  return (
    <GlobalDataProvider language={language} altUrls={altUrls}>
      <MainLayout>
        {seoProperties && <Seo {...seoPropertiesToProps(seoProperties)} />}
        <Slices body={loginpage.data.body} />
      </MainLayout>
    </GlobalDataProvider>
  )
}
