import React, { useState, Fragment } from 'react'
import { Row, Col, Container } from '@kogk/common'
import { DonationWidget, SplitHeading } from '@cmp/site'
import { TopHero, DonationForm, RichText } from '@cmp/sections'
import cn from 'classnames'
import qs from 'qs'
import styles from './DonationPage.module.scss'
import {
  formatSSN,
  formatPhone,
  formatCurrency,
} from '@src/utils'
import { useDonationStore } from '@src/stores'
import {
  DEFAULT_MONTHLY,
  DEFAULT_SINGLE,
  FALLBACK_AMOUNT,
} from '@src/utils/index'

const getInitialAmount = () => {
  const storeAmount = useDonationStore((state) => state.selectedAmount)
  const isBrowser = typeof window !== 'undefined'
  const params = isBrowser ? qs.parse(window.location.search.slice(1)) : []
  const paramAmount = parseInt(params.amount, 10)
  const initAmount =  isNaN(paramAmount) || paramAmount <= 0 ? storeAmount : paramAmount
  return initAmount || FALLBACK_AMOUNT
}

const getTextWithAmount = (text, amount) => {
  const amountFormatted = formatCurrency(amount)
  return text.replace(/\$+/g, amountFormatted)
}
export default ({
  heroText,
  heroTextSuccess,
  successMessage,
  introText,
  dim2,
  campaign,
  itemCode,
  monthly,
  zenterAudience,
  termsUrl,
  styrktarVerkefni,
  ...props
}) => {
  const [successData, setSuccessData] = useState()
  const initialAmount = getInitialAmount()
  const [amount, setAmount] = useState(initialAmount)

  heroText = getTextWithAmount(heroText, amount)
  introText = getTextWithAmount(introText, amount)

  if (successData) {
    return (
      <Layout
        heroText={heroTextSuccess || heroText}
        successText={successMessage ||'Takk fyrir stuðninginn'}
        {...props}
      >
        <Summary amount={amount} monthly={monthly} {...successData} />
      </Layout>
    )
  }
  const fallbackAmounts = monthly ? DEFAULT_MONTHLY : DEFAULT_SINGLE
  const amounts = styrktarVerkefni?.data?.amounts ?? fallbackAmounts
  

  return (
    <Layout heroText={heroText} introText={introText} {...props}>
        {amounts && amounts.length > 0 && <DonationWidget
          amounts={amounts}
          standalone
          updateDonationPageAmount={setAmount}
          initialAmount={initialAmount}
        />}
      <DonationForm
        amount={amount}
        dim2={dim2}
        campaign={campaign}
        itemCode={itemCode}
        monthly={monthly}
        termsUrl={termsUrl}
        zenterAudience={zenterAudience}
        onSuccess={setSuccessData}
      />
    </Layout>
  )
}

const Layout = ({ heroText, introText, successText, title, image, richText, children }) => {

  return (
    <Fragment>
      <TopHero imageArr={[]} heading={heroText} />
      <Container fluid>
        <Row className="pt-5 mb-2 mb-md-0 pb-4 pb-md-6">
          <Col col={{ lg: 4 }} offset={{ lg: 1 }}>
          <div className={styles.formTopText}>
            {Boolean (introText) ? <h3>{introText}</h3>
              : Boolean(successText) ? <h5>{successText}</h5>
              : null}
          </div>
            {children}
          </Col>
          <Col col={{ lg: 5 }} offset={{ lg: 1 }}>
            <div className={styles.image}>
              <img alt=" " src={image} />
            </div>

            <SplitHeading
              tag="h5"
              classNames={cn(styles.heading, 'hdln--5 hdln--black-blue')}
            >
              {title}
            </SplitHeading>

            <article className="donation-page">
              <RichText html={richText} />
            </article>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

const Summary = ({ amount, monthly, name, ssn, phone, email }) => {
  return (
    <div>
      <p className="eyebrows eyebrows--black mb-3">Samantekt</p>
      <p>
        Styrkur: <b>{monthly ? 'Mánaðarlegur' : 'Eitt skipti'}</b>
        <br />
        Upphæð: <b>{formatCurrency(amount)} kr.</b>
      </p>
      <p>
        Nafn: <b>{name}</b>
        <br />
        Kennitala: <b>{formatSSN(ssn)}</b>
        <br />
        Farsími: <b>{formatPhone(phone)}</b>
        <br />
        Netfang: <b>{email}</b>
      </p>
    </div>
  )
}
