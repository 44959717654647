import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { usePreview, makeSlices } from '@kogk/gatsby-theme-prismic'
import '@src/data/prismic-fragments/post'
import { MainLayout, Video, PostTag, SEO } from '@cmp/site'
import { BannerImage, LatestNews } from '@cmp/sections'
import {
  formatDateString,
  splitCardHeadings,
  seoPropertiesToProps,
} from '@src/utils'
import { Container, Row, Col } from '@kogk/common'
import sliceMapping from '@src/data/prismic-slice-mapping/page-slices'
import { GlobalDataProvider } from '@src/data/global-data'
import { Link } from '@kogk/gatsby-theme-base'
import { AngleRight } from '../../../assets/icons'
import Authentication from '../Authentication/Authentication'

const Slices = makeSlices(sliceMapping)

export default ({ data: staticData, location }) => {
  const data = usePreview(staticData)
  const breadcrumbs = data.prismicPost.data?.breadcrumbs.filter(
    (bc) => bc.parent_page.document,
  )
  const language = data.prismicPost.lang.substring(0, 2)
  const loginpage = data.prismicPost.loginpage
  const isImportedNews =
    !!data.prismicPost.data.body.filter(
      (i) => i.__typename === 'PrismicPostBodyUniImpNewsImage',
    ).length > 0

  const {
    date,
    excerpt: { text: excerpt },
    feature_image: { url: featureImage },
    image_text: { text: imageText },
    category,
    embedded_media: embeddedMedia,
    post_type: postType,
  } = data.prismicPost.data

  const headingCleanup = splitCardHeadings(
    data.prismicPost.data.page_title.text,
  )
  const pageTitle = headingCleanup[0]

  const formattedDate = !postType ? formatDateString(date) : null

  const altUrls = [
    ...data.prismicPost.alternate_languages.map(({ document }) => document),
    { url: location.pathname, lang: language },
  ]

  const seoProperties = data.prismicPost.data.seo_properties
  const ogImage = data.prismicPost.data.override_default_image.url ? data.prismicPost.data.override_default_image.url : featureImage

  const memberPost = data.prismicPost.tags.length > 0

  return (
    <Authentication
      loginpage={loginpage}
      language={language}
      seoProperties={seoProperties}
      altUrls={altUrls}
    >
      <GlobalDataProvider language={language} altUrls={altUrls}>
        <MainLayout pageTitle={pageTitle} overrideDefaultImage={ogImage}>
          {seoProperties && <SEO {...SEO.seoPropertiesToProps(seoProperties)} />}
          <Container fluid className="pt-5 pb-lg-5 mb-lg-4">
            <Row>
              <Col col={{ lg: 10 }} offset={{ lg: 1 }}>
                {formattedDate && (
                  <p className="eyebrows eyebrows--black mb-0">
                    {formattedDate}
                  </p>
                )}
                <div className="crumbTrail d-flex">
                  {breadcrumbs &&
                    breadcrumbs.map((item, key) => {
                      const {
                        page_title: pageTitle,
                      } = item.parent_page.document.data
                      return (
                        <Fragment key={key}>
                          <Link
                            className="eyebrows eyebrows--grey"
                            to={item.parent_page.url}
                          >
                            {pageTitle.text}
                          </Link>
                          <AngleRight />
                        </Fragment>
                      )
                    })}
                  {postType && <PostTag tag={postType} height="tall" />}
                </div>

                <h3 className="mt-3 mb-4">{pageTitle}</h3>
                <p className="parag parag--large mb-3 pb-3">{excerpt}</p>

                {featureImage &&
                  !isImportedNews &&
                  !embeddedMedia.embed_url && (
                    <BannerImage
                      label
                      image={featureImage}
                      text={imageText}
                      className="mb-4"
                    />
                  )}
              </Col>

              {embeddedMedia && !isImportedNews && (
                <Col
                  offset={{ lg: 1 }}
                  col={{ lg: 10 }}
                  className="d-block pb-2"
                >
                  <Video url={embeddedMedia} />
                </Col>
              )}

              <Col col={{ lg: 7 }} offset={{ lg: 1 }}>
                <article>
                  <Slices body={data.prismicPost.data.body} />
                </article>
              </Col>

              {!memberPost && (
                <Col col={{ lg: 3 }}>
                  <LatestNews />
                </Col>
              )}
            </Row>
          </Container>
        </MainLayout>
      </GlobalDataProvider>
    </Authentication>
  )
}

export const query = graphql`
  query genericPostQuery($prismicId: ID!) {
    prismicPost(prismicId: { eq: $prismicId }) {
      ...prismicPostFragmentFull
      loginpage {
        ...prismicPageFragmentFull
      }
    }
  }
`
